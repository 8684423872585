<template>
  <div class="myOrder">
    <van-tabs v-model="active" class="myOrderTab" title-active-color="#FB884F" color="#FB884F" line-width="0.42rem">
      <van-tab title="待评价">
        <in-the-lease :listData="remainToBeEvaluatedData"></in-the-lease>
        <div class="orderBackground" v-show="remainToBeEvaluatedData.length==0">
          <img :src="require('../../images/noData.png')"/>
          <p>暂无评论数据</p>
        </div>
      </van-tab>
      <van-tab title="已评价">
        <in-the-lease :listData="InTheLeaseData"></in-the-lease>
        <div class="orderBackground" v-show="InTheLeaseData.length==0">
          <img :src="require('../../images/noData.png')"/>
          <p>暂无评论数据</p>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import InTheLease from '../../order/components/InTheLease';
import leaseApi from "../../../utils/leaseApi";
import TEMPURL from "../../../utils/tempurl";
export default {
  name: "myOrder",
  components:{
    InTheLease
  },
  data() {
    return {
      active: 2,
      // 待评价数据
      remainToBeEvaluatedData:[],
      InTheLeaseData:[]
    }
  },
  watch: {
  },
  methods:{
    async getEvaluatedOrder(){
      const {data} = await leaseApi.getEvaluatedOrder();
      data.forEach((e)=>{
        e.productPic=TEMPURL + e.productPic
      })
      let noneEvaluateId =  data.filter(d => d.evaluateId == null)
      console.log(noneEvaluateId)
      let hasEvaluatedId = data.filter(d => d.evaluateId != null)
      this.remainToBeEvaluatedData=noneEvaluateId
      this.InTheLeaseData = hasEvaluatedId
    }
  },
  created(){
    this.getEvaluatedOrder()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  display: flex !important;
  justify-content: left !important;
  height: 1.14rem !important;
  padding-left: 0.51rem !important;;
  background: #FFFFFF;
  margin-bottom: 0.33rem;
}

/deep/ .van-tab {
  margin-right: 0.86rem !important;
}

/deep/ .van-tab--active {
  font-weight: bold;
}

.myOrder {
  background: #F6F6F6;
  height: 100vh;
}
.orderBackground{
  text-align: center;
  img{
    width: 6.94rem;
    margin-top: 50%;
  }
  p{
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
    margin-top: 0.56rem;
  }
}
</style>
